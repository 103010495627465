<template>
  <div id="page-user-view-edit">
    <div id="user-data">
      <b-modal
        v-model="openVerifyPopup"
        centered
        hide-footer
        no-close-on-backdrop
        class="container"
        title="Verify your contact information"
      >
        <ContactVerification
          :input-type="verifyType"
          @close-verification="closeVerification"
        />
      </b-modal>
      <b-card
        v-if="Object.keys(accountInfo).length > 0"
        class="mb-base"
      >
        <div
          v-if="$store.state.auth.ActiveUser.account_type === this.$UserGroups.SUPERUSER"
          class="vx-row my-1 ml-1"
        >
          <h6>{{ profileType ? 'My Profile': 'Company Profile' }}</h6>
        </div>

        <!-- Avatar -->
        <div class="d-flex flex-row flex-wrap justify-content-start">
          <!-- Avatar Col -->
          
          <b-col
            cols="12"
            md="4"
            lg="2"
          >
            <div
              class="d-flex justify-content-center mb-2 w-100"
              style="width: 200px; height: 200px"
            >
              <b-img
                v-if="profileType"
                :src="
                  accountInfo.avatar !== 'default' && $isNotEmpty(accountInfo.avatar) && accountInfo.avatar !== ''
                    ? accountInfo.avatar
                    : require('@/assets/images/placeholder/no-image.jpg')
                "
                class="float-left"
                style="width: 200px; height: 200px"
              />

              <b-img
                v-else
                :src="companyInfo.avatar === 'default' || companyInfo.avatar == null || companyInfo.avatar === '' ? require('@/assets/images/placeholder/no-image.jpg'): companyInfo.avatar
                "
                class="float-left"
                style="width: 200px; height: 200px"
              />
            </div>
            <div
              class="w-100"
            >
              <b-button
                id="edit-btn"
                icon-pack="feather"
                icon="icon-edit"
                class="w-100"
                rounded
                variant="primary"
                @click="gotoEdit"
              >Edit</b-button>
            </div>
            <div
              v-if="false"
              class="w-100 mt-2"
            >
              <b-button
                icon-pack="feather"
                icon="icon-edit"
                class="w-100"
                variant="warning"
                @click="resendVerification"
              >Verify Account</b-button>
            </div>
          </b-col>
          <!-- Information - Col 1 -->
          <b-col
            cols="12"
            md="4"
            lg="5"
          >
            <table
              class="w-100"
              :class="profileType ? 'h-auto' : 'h-12 mt-4'"
            >
              <tr v-if="profileType">
                <td class="font-semibold p-1 w-50">
                  Full Name
                </td>
                <td class="p-1 w-50">
                  {{ getDefaultValue(accountInfo.fullname) }}
                </td>
              </tr>
              <tr v-if="profileType">
                <td class="font-semibold p-1">
                  Email Address
                </td>
                <td class="p-1 d-flex d-flex-wrap">
                  <div class="md:mt-1 break-word mr-1">
                    {{ getDefaultValue(accountInfo.email_address) }}
                  </div>
                  <div>

                    <feather-icon
                      v-if="accountInfo.email_verified"
                      v-b-tooltip.hover.top="'Your email address has been verified.'"
                      size="18"
                      class="text-success"
                      icon="CheckCircleIcon"
                    />

                    <feather-icon
                      v-else
                      v-b-tooltip.hover.top="'Your email address has not been verified yet.  Click here to verify your email address'"
                      size="18"
                      class="text-danger"
                      icon="XCircleIcon"
                      @click="verifyContact('email')"
                    />
                  </div>
                </td>
              </tr>

              <tr v-if="profileType">
                <td class="font-semibold p-1">
                  Contact Number
                </td>
                <td class="p-1 d-flex d-flex-wrap">
                  <div class="md:mt-1 break-word mr-1">
                    {{ getDefaultValue(accountInfo.phone_number) }}
                  </div>
                  <div>

                    <feather-icon
                      v-if="accountInfo.phone_number_verified"
                      v-b-tooltip.hover.top="'Your phone number has been verified.'"
                      size="18"
                      class="text-success"
                      icon="CheckCircleIcon"
                    />

                    <feather-icon
                      v-else
                      v-b-tooltip.hover.top="'Your phone number has not been verified yet. Click here to verify your phone number'"
                      class="text-danger"
                      size="18"
                      icon="XCircleIcon"
                      @click="verifyContact('phone')"
                    />
                  </div>

                </td>

              </tr>
              <tr
                v-if="
                  $route.name === 'corporate'
                "
              >
                <td class="font-semibold p-1">
                  Company Name
                </td>
                <td class="p-1">
                  {{
                    getDefaultValue(
                      companyInfo.company_name
                    )
                  }}
                </td>
              </tr>
              <tr v-if="!profileType">
                <td class="font-semibold p-1">
                  UEN Number
                </td>
                <td class="p-1 -m-6">
                  {{ getDefaultValue(companyInfo.uen_no) }}
                </td>
              </tr>

              <tr v-if="accountInfo.account_type === this.$AccountTypes.APPLICANT">
                <td class="font-semibold p-1">
                  Date of birth
                </td>
                <td class="p-1">
                  {{ getDefaultValue(accountInfo.date_of_birth) }}
                </td>
              </tr>
              <tr v-if="accountInfo.account_type === this.$AccountTypes.APPLICANT">
                <td class="font-semibold p-1">
                  Highest Qualification
                </td>
                <td class="p-1">
                  {{ getDefaultValue(accountInfo.highest_qualification) }}
                </td>
              </tr>

              <tr v-if="!profileType">
                <td class="font-semibold p-1">
                  Company Approved
                </td>
                <td class="p-1 -m-6">
                  <div class="d-flex align-items-center">
                    <b-badge
                      pill
                      class=""
                      :class="companyInfo.verified ? 'bg-success' : 'bg-danger'"
                    >
                      <span>
                        {{ companyInfo.verified ? 'Approved' : 'Not Approved' }}
                      </span>
                    </b-badge>
                  </div>
                </td>
              </tr>

              <tr v-if="accountInfo.account_type === this.$AccountTypes.APPLICANT">
                <td class="font-semibold p-1">
                  Gender
                </td>
                <td class="p-1">
                  {{ getDefaultValue(accountInfo.gender) }}
                </td>
              </tr>

              <tr
                v-if="accountInfo.account_type === this.$AccountTypes.APPLICANT"
                cl
              >
                <td class="font-semibold p-1">
                  Employment Status
                </td>
                <td class="flex flex-wrap p-1">
                  <p class="m-0 p-0">{{ getDefaultValue(accountInfo.employment_status) }}</p>
                </td>
              </tr>

              <tr v-if="profileType">
                <td class="font-semibold p-1">
                  Two Factor
                </td>
                <td class="p-1">
                  {{ accountInfo.two_factor_active ? 'Enabled' : 'Disabled' }}
                </td>
              </tr>

              <tr v-if="profileType">
                <td class="font-semibold p-1">
                  Password Expires
                </td>
                <td class="p-1 text-danger">
                  {{ getDefaultValue(accountInfo.days_left) }}
                </td>
              </tr>
            </table>
          </b-col>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <b-col
            v-if="accountInfo.account_type === this.$AccountTypes.APPLICANT"
            cols="12"
            md="4"
            lg="5"
          >
            <table class="divide-x divide-light-blue-400 w-100">
              <tr>
                <td class="font-semibold p-1 w-50">
                  Citizenship
                </td>
                <td class="p-1 w-50">
                  <template>
                    {{ getDefaultValue(accountInfo.citizenship) }}
                  </template>
                  <!-- <template v-else>
                    {{ $capitalizeString(accountInfo.citizenship_others) }}
                  </template> -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold p-1">
                  Preferred Work Location
                </td>
                <td class="p-1">
                  {{ getDefaultValue(accountInfo.preferred_location, true) }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold p-1">
                  Preferred Job Position
                </td>
                <td class="p-1">
                  {{ getDefaultValue(accountInfo.preferred_position, true) }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold p-1">
                  Preferred Job Industry
                </td>
                <td class="p-1">
                  <p class="m-0 p-0">{{ getDefaultValue(accountInfo.preferred_industry, true) }}</p>
                </td>
              </tr>
              <tr>
                <td class="font-semibold p-1">
                  Residential Postal
                </td>
                <td class="p-1">
                  {{ getDefaultValue(accountInfo.postal_code) }}
                </td>
              </tr>

              <tr>
                <td class="font-semibold p-1">
                  Notice Period
                </td>
                <td class="p-1">
                  {{
                    getDefaultValue(
                      accountInfo.availability_number ? accountInfo.availability_number: '' +
                        ' ' +
                        accountInfo.availability_period ? accountInfo.availability_period : '',
                    )
                  }}
                </td>
              </tr>

              <tr>
                <td class="font-semibold p-1">
                  Resume Uploaded
                </td>
                <td class="p-1">
                  {{ accountInfo.resume_file ? 'Yes' : 'No' }}
                </td>
              </tr>
            </table>
          </b-col>
          <!-- /Information - Col 2 -->
        </div>
      </b-card>

      <div
        v-if="(isTablet || isMobile) && accountInfo.account_type === this.$AccountTypes.APPLICANT"
        class="tabs-container"
      >
        <b-tabs
          lazy
          pills
          class="tab-action-btn-fill-container"
          justified
        >
          <b-tab
            id="view-employment"
            title="Employments"
            icon-pack="feather"
            icon="icon-layers"
            @click="onTab('employment')"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="LayersIcon"
                size="18"
              />
              <span>Employments</span>
            </template>
          </b-tab>

          <b-tab
            id="view-education"
            title="Education"
            icon-pack="feather"
            icon="icon-book"
            @click="onTab('educations')"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="BookIcon"
                size="18"
              />
              <span>Educations</span>
            </template>
          </b-tab>
        </b-tabs>
      </div>

      <div class="d-flex">

        <b-row class="w-100">
          <b-col
            v-if="showEmployments"
            cols="12"
            sm="12"
            md="12"
            lg="6"
          >
            <div
              v-if="accountInfo.account_type === this.$AccountTypes.APPLICANT"
              class="mb-base"
            >
              <b-row
                v-if="isTablet || isMobile"
                class="mb-1 mt-2"
              >
                <h4 class="ml-1">
                  Employment Information
                </h4>
              </b-row>
              <div
                v-if="employmentInfo.length > 0"
              >
                <b-card
                  v-for="(item, index) in employmentInfo"
                  :key="index"
                  class="shadow-md px-1 "
                  style="height: auto;"
                >
                  <div class="vx-row">
                    <h4 class="m-0 p-0 font-weight-bolder">
                      {{ item.position }}
                    </h4>
                  </div>

                  <div
                    class="d-flex flex-row flex-gap-md align-items-center"
                  >
                    <font-awesome-icon
                      v-b-tooltip.hover.top="`Company Name`"
                      icon="fa-building"
                      class="text-primary"
                    />
                    <h6 class="m-0 p-0">{{ item.company_name }}</h6>
                  </div>

                  <div
                    class="d-flex flex-row flex-gap-md align-items-center"
                  >
                    <font-awesome-icon
                      v-b-tooltip.hover.top="`Employment Period`"
                      icon="fa-calendar-days"
                      class="text-primary"
                    />
                    <h6 class="m-0 p-0">
                      {{ processDate(item.start_date) }} -
                      {{ processDate(item.end_date) }}
                    </h6>
                    <h6 class="m-0 p-0 font-semibold">
                      ({{ processDiff(item.start_date, item.end_date) }})
                    </h6>
                  </div>

                  <div
                    class="d-flex flex-row flex-gap-md align-items-center"
                  >
                    <font-awesome-icon
                      v-b-tooltip.hover.top="`Description`"
                      icon="fa-message"
                      class="text-primary"
                    />
                    <p
                      class="m-0 p-0"
                      style="white-space: pre-wrap;"
                    >{{ item.description }}</p>
                  </div>
                </b-card>
              </div>

              <b-card
                v-else
                class="shadow-md"
              >
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 7rem;"
                >
                  <b class="m-0 p-0">
                    No employment information has been added yet.
                  </b>
                </div>
              </b-card>
            </div>
          </b-col>

          <b-col
            v-if="showEducations"
            cols="12"
            sm="12"
            md="12"
            lg="6"
          >
            <div
              v-if="accountInfo.account_type === this.$AccountTypes.APPLICANT"
              class="mb-base"
            >
              <b-row
                v-if="isTablet || isMobile"
                class="mb-1 mt-2"
              >
                <h4 class="ml-1">
                  Education Information
                </h4>
              </b-row>

              <div
                v-if="educationInfo.length > 0"
              >
                <b-card
                  v-for="(item, index) in educationInfo"
                  :key="index"
                  class="shadow-md w-100 px-1"
                  style="height: 7rem;"
                >
                  <div class="">
                    <h4 class="m-0 p-0 font-weight-bolder">
                      {{ item.field_of_study }}
                    </h4>
                  </div>
                  <div class="">
                    <div class="d-flex flex-row flex-gap-md">
                      <font-awesome-icon
                        v-b-tooltip.hover.top="`School Name`"
                        icon="fa-building"
                        class="text-primary"
                      />
                      <h6 class="m-0 p-0">{{ item.school_name }}</h6>
                    </div>

                    <div class="d-flex flex-row flex-gap-md divide-x divide-gray-400">
                      <font-awesome-icon
                        v-b-tooltip.hover.top="`Study Period`"
                        icon="fa-calendar-days"
                        class="text-primary"
                      />
                      <h6
                        class="m-0 p-0"
                      >{{ processDate(item.start_date) }} -
                        {{ processDate(item.end_date) }}
                      </h6>
                      <h6 class="font-semibold">
                        ({{ processDiff(item.start_date, item.end_date) }})
                      </h6>
                    </div>
                  </div>
                </b-card>
              </div>

              <b-card
                v-else
                class="shadow-md"
              >
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 7rem;"
                >
                  <b class="m-0 p-0">
                    No education information has been added yet.
                  </b>
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </div>

      <v-tour
        v-if="!finished_tour"
        name="ProfileTour"
        :steps="steps"
        :callbacks="tourCallbacks"
        :options="{ highlight: false }"
      />
      <!-- Permissions -->
    </div>
  </div>
</template>

<script>
import { format, formatDistance } from 'date-fns'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ContactVerification from '@/components/VerifyContact.vue'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'

import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBTooltip,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BAlert,
  BImg,
} from 'bootstrap-vue'

import EditProfile from './EditProfile.vue'

export default {
  components: {
    ContactVerification,
    EditProfile,
    BCard,
    BImg,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBTooltip,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [onResizeMixins],
  props: {
    accountInfo: { type: Object, required: true },
    companyInfo: { type: Object, default: function () { return {} } },
    employmentInfo: { type: Array, default: function () { return [] } },
    educationInfo: { type: Array, default: function () { return [] } },
    profileType: { type: Boolean, default: true },
  },
  data() {
    return {
      openVerifyPopup: false,
      verifyType: 'phoneNumber',
      tourCallbacks: {
        onSkip: this.finishTour,
        onFinish: this.finishTour,
        onPreviousStep: this.changeOfTab,
        onNextStep: this.changeOfTab,
      },
      finished_tour: false,
      steps: [
        {
          target: '#edit-btn', // We're using document.querySelector() under the hood
          content: 'Click here to edit your account details.',
        },
        {
          target: '#edit-btn', // We're using document.querySelector() under the hood
          content: 'Click here to edit your account details.',
        },
      ],
      isLoading: true,

      showEmployments: true,
      showEducations: true,
    }
  },

  watch: {
    isTablet(val) {
      if (val) {
        this.showEmployments = true
        this.showEducations = false
      } else {
        this.showEmployments = true
        this.showEducations = true
      }
    },
    isMobile(val) {
      if (val) {
        this.showEmployments = true
        this.showEducations = false
      } else {
        this.showEmployments = true
        this.showEducations = true
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT) {
      this.getConfigInfo()
      if (!this.finished_tour) {
        this.$tours.ProfileTour.start()
      }
    }
  },
  methods: {
    closeVerification() {
      this.$emit('reload-profile')
      this.openVerifyPopup = false
    },
    verifyContact(contactType) {
      if (contactType === 'email') {
        this.verifyType = 'email'
        this.openVerifyPopup = true
      } else {
        this.verifyType = 'phoneNumber'
        this.openVerifyPopup = true
      }
    },
    finishTour() {
      const { id } = this.$store.state.auth.ActiveUser
      const record = { tour_finished: true, tour_finished_on: new Date(), user_id: id }
      this.$http
        .put('/api/account-config', record)
        .then(response => {
          const { config } = response.data

          this.finished_tour = config.finished_tour
        })
        .catch(error => {})
    },
    gotoEdit(event) {
      if (this.$route.name === 'profile') {
        this.$router.push({ name: 'edit-profile' })
      } else {
        this.$router.push({ name: 'edit-corporate' })
      }
      // this.$router.push({name: 'edit-profile'})
      // this.$emit('clicked', false)
    },
    getConfigInfo() {
      this.$http
        .get('/api/account-config')
        .then(response => {
          const { config } = response.data
          this.finished_tour = config.tour_finished
        })
        .catch(error => {})
    },
    resendVerification() {
      const user_id = this.$store.state.auth.ActiveUser.id
      this.$http
        .get(`/api/auth/resend-verification/${user_id}`)
        .then(response => {
          const { message } = response.data
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Verifcation Update',
                icon: 'AlertCircleIcon',
                text: message,
                variant: 'success',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
        .catch(error => {})
    },
    getDefaultValue(item, list = false) {
      if (list) {
        if (item === null || item === undefined) {
          return 'Not Set'
        }
        if (item.length === 0) {
          return 'Not Set'
        }

        return item.join(', ')
      }
      if (
        item === null
        || item === undefined
        || item === ''
        || item.length <= 3
      ) {
        return 'Not Set'
      }
      return item
    },
    processDate(date_string) {
      try {
        if (date_string.toLowerCase() === 'current') {
          return format(new Date(), 'MMM yyyy')
        }
        return format(new Date(date_string), 'MMM yyyy')
      } catch (error) {
        return 'NA'
      }
    },

    processDiff(start_date, end_date) {
      try {
        if (end_date.toLowerCase() === 'current') {
          end_date = new Date()
        }
        return formatDistance(new Date(end_date), new Date(start_date))
      } catch (error) {
        return 'NA'
      }
    },

    onTab(tabName) {
      if (tabName === 'educations') {
        this.showEmployments = false
        this.showEducations = true
      } else {
        this.showEmployments = true
        this.showEducations = false
      }
    },

    changeOfTab(current_step) {
      this.gotoEdit()
    },
  },
}
</script>

<style lang="scss">

.break-word {
  -ms-word-break: break-all; /* Be VERY careful with this, breaks normal words wh_erever */
  word-break: break-all; /* Non standard for webkit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
